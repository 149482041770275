// import "/node_modules/select2/src/scss/core";
// import Swal from 'sweetalert2' - вирус хохлов
// require('./bootstrap');

jQuery(function($){
    // $.mask.autoclear = !1;
    // $("INPUT[type=tel]").mask("+7 (999) 999-99-99",{"placeholder":" "});
    //$("#tin").mask("99-9999999");
    //$("#ssn").mask("999-99-9999");
    $("INPUT.datetime").mask("99.99 99:99",{placeholder:"дд.мм 12:00"});
});

jQuery(document).ready(function(){
    //jQuery('[data-toggle="tooltip"]').tooltip();
    const exp1 = new PhoneReplacer(jQuery('INPUT[type=tel]'), {
        masks: [
            ['+7 (DDD) DDD-DD-DD', ['+7', '7']],
            ['8 (DDD) DDD-DD-DD', ['8']],
            // ['+38 (DDD) DDD-DD-DD', ['+38', '38', '80']],
        ],
        masksDef: '+7 (DDD) DDD-DD-DD',
        placeholder: '_',
        addPlaceholder: true
    });
    
    // scroll top page
    jQuery('#btnUpPageAll').on('click', function(){
        var thisObj = jQuery(this);
        //thisObj.removeClass('active');
        jQuery('html, body').animate({ scrollTop: jQuery('body').offset().top }, 1000);
    });
    // scroll get
    jQuery(window).on('scroll', function(){
        var thisObj = jQuery(this),
            topList = parseFloat(window.pageYOffset);
        if(topList < 1000){
            jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').removeClass('active') : false;
        } else {
            !jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').addClass('active') : false;
        }
    });
    
    
    jQuery('INPUT.numberInput').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
        
        //console.log(String.fromCharCode(e.which));
        //console.log(list.indexOf(String.fromCharCode(e.which)));
        if(String.fromCharCode(e.which) == '.'){
            if(val.length <= 0){
                val = '0.';
                jQuery(this).val('0.');
            }
            if(val.indexOf('.') >= 0){
                return false;
            }
        }
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInputInt').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInput, INPUT.numberInputInt').on('change', function(e){
        var numberText = jQuery(this).val();
        numberText = numberText.replace(/ /, '');
        numberText = parseFloat(numberText);
        if(isNaN(numberText)){
            numberText = 0;
        }
        jQuery(this).val(numberText);
        return false;
    });
    
    // INPUT LABEL
    jQuery('.inputBlock.labelPlaceholder INPUT').on('focus', function(e){
        var thisObj = jQuery(this),
            inputBlock = thisObj.closest('.inputBlock');
        inputBlock.addClass('active');
        //return false;
    }).on('blur', function(e){
        var thisObj = jQuery(this),
            inputBlock = thisObj.closest('.inputBlock');
        if(thisObj.val().length > 0){
            inputBlock.addClass('hasData');
        } else if(thisObj.val().length == 0 && inputBlock.hasClass('hasData')){
            inputBlock.removeClass('hasData');
        }
        inputBlock.removeClass('active');
        //return false;
    });
    
    // INPUT change ERROR
    jQuery('BODY').on('click', 'INPUT.error, TEXTAREA.error, SELECT.error, CHECKBOX.error', function(){
        var thisObj = jQuery(this);
        thisObj.removeClass('error');
    });
    
    /* RESIZE PAGE */
    jQuery(window).resize(function(){
        //var size = $(window).width();
        typeof jQuery('.btnOpenMenu.active') != "undefined" && jQuery('.btnOpenMenu.active').length > 0 ? getMobileMenu(jQuery('.btnOpenMenu.active')) : false;
        //console.log(size);
    });
    /* END RESIZE PAGE */
    

    jQuery('BODY').on('click', '.getBigImg', function(){
        let thisObj = jQuery(this),
            target = thisObj.data('target'),
            resource = thisObj.data('resource'),
            targetObj = jQuery(target);
        if(typeof targetObj != "undefined" && !targetObj.hasClass('active')){
            thisObj.closest('.getBigImg_closest').find('.active').removeClass('active');
            thisObj.addClass('active');
            targetObj.closest('.getBigImg_closest').find('.active').removeClass('active');
            targetObj.addClass('active');
        }
    });

    jQuery('BODY').on('click', '.getListOpen', function(){
        let thisObj = jQuery(this),
            target = thisObj.data('target'),
            targetObj = typeof target != "undefined" ? jQuery(target) : "undefined";
        if(typeof targetObj != "undefined"){
            let hasOpen = targetObj.hasClass('open');
            if(hasOpen){
                // autoHeightAnimate(thisObj.closest('.blockDesign'), 1000).then(() => jQuery(target).removeClass('open'));
                thisObj.closest('.blockDesign').css({'height': ''});
                jQuery(target).removeClass('open');
            } else {
                autoHeightAnimate(thisObj.closest('.blockDesign'), 300);
                setTimeout(()=>jQuery(target).addClass('open'), 300);
            }
        }
    });
    
    /* windowHead */
    var scrollEndPoint = jQuery(window).scrollTop();
    function getHeadClassTop(){
        let head = jQuery('.ownerHead'),
            scrollT = jQuery(window).scrollTop(),
            hasTop = head.hasClass('topFixed');
        if(scrollT > 10 && !hasTop){
            head.addClass('topFixed');
        } else if(scrollT < 10 && hasTop){
            head.removeClass('topFixed');
            hasTop = false;
        }
        if(scrollT > 100 && scrollEndPoint < scrollT && hasTop){
            head.addClass('topClear');
        } else if(scrollT > 100 && scrollEndPoint > scrollT && hasTop){
            head.removeClass('topClear');
        }
        //console.log(scrollEndPoint);
        //console.log(scrollT);
        scrollEndPoint = scrollT;
    }
    getHeadClassTop();
    jQuery(window).on('scroll', function(e){
        getHeadClassTop();
    });
    jQuery(window).on('mousemove', function(e){
        let currentMousePosY = event.pageY - jQuery(window).scrollTop();
        //console.log(currentMousePosY);
        if(currentMousePosY < 90){
            scrollEndPoint = scrollEndPoint * 2;
            getHeadClassTop();
        }
    });
    /* end windowHead */
    
    /* windowMenu */
    function getMobileMenu(obj){
        var thisObj = obj,
            btnActive = thisObj.hasClass('active'),
            target = thisObj.data('target'),
            targetBtns = thisObj.data('btns'),
            listBtns = jQuery('.'+targetBtns),
            objTarget = jQuery('#'+target);
        if(!btnActive){
            jQuery('BODY').addClass('modal-open');
            listBtns.addClass('active');
            objTarget.addClass('active').animate({"opacity": "1"}, 200);
            // objTarget.animate({"opacity": "1"}, 10)
            //     .find('.contentHeaderMobile').animate({"right": "0px"}, 300)
            //     .addClass('active');
        } else {
            jQuery('BODY').removeClass('modal-open');
            listBtns.removeClass('active');
            // objTarget.find('.contentHeaderMobile').animate({"right": "-430px"}, 200);
            // objTarget.animate({"opacity": "0"}, 200, function(){jQuery(this).removeClass('active')});
            objTarget.animate({"opacity": "0"}, 200, function(){jQuery(this).removeClass('active')});
        }
    }
    jQuery('.btnOpenMenu').on('click', function(e){
        var thisObj = jQuery(this);
        getMobileMenu(thisObj)
        return false;
    });
    // jQuery('BODY').on('click', function(e){
    //     let activeMenu = jQuery(this).find('.btnOpenMenu.active');
    //     if(activeMenu.length && jQuery(e.target).closest('.wrapperHeadMobile').length == 0){
    //         getMobileMenu(activeMenu);
    //     }
    // });
    jQuery('.wrapperHeadMobile').on('click', function(e){
        if(e.target === this){
            var thisObj = jQuery(this).find('.btnOpenMenu');
            getMobileMenu(thisObj)
            return false;
        }
    });
    jQuery('.wrapperHeadMobile a.getModal').on('click', function(e){
        var thisObj = jQuery(this),
            thisObjBtn = jQuery(this).parents('.wrapperHeadMobile').find('.btnOpenMenu');
        getMobileMenu(thisObjBtn)
        //return false;
    });
    jQuery('.getBody.nav-link').on('click', function(e){
        var thisObj = jQuery(this),
            link = thisObj.attr('href'),
            //target = link.replace(/^\/\?\#/, ''),
            target = thisObj.data('target'),
            blockTarget = typeof target != "undefined" ? jQuery('#'+target) : false;
        jQuery('.btnOpenMenu').click();
        //console.log(target);
        //console.log(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0);
        if(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0){
            jQuery('html, body').animate({ scrollTop: (blockTarget.offset().top - 100) }, 1000);
            history.pushState(null, null, link);
            //return false;
        } else {
            window.location.href = link;
        }
        // easeOutCubic
        return false;
    });
    // child menu
    jQuery('.dropdown SPAN.nav-link.dropdown-toggle').on('click', function(e){
        var thisObj = jQuery(this),
            dropdown = thisObj.closest('.dropdown'),
            active = dropdown.hasClass('open');
        if(!active){
            jQuery('.nav .dropdown').removeClass('open');
            dropdown.addClass('open');
        } else {
            dropdown.removeClass('open');
        }
        return false;
    });
    jQuery('BODY').on('click', function(e){
        let activeMenu = jQuery(this).find('.dropdown.open');
        if(activeMenu.length && jQuery(e.target).closest('.dropdown.open').length == 0){
            activeMenu.removeClass('open');
        }
    });
    /* end windowMenu */
    jQuery('BODY').on('click', '.navQuiz .dropQuiz-toggle', function(){
        let thisObj = jQuery(this),
            item = thisObj.closest('.nav-item')
            drop = thisObj.next('.dropQuiz-menu')
            isOpen = item.hasClass('open');
        if(!isOpen){
            item.addClass('open');
            drop.addClass('open');
            // runAudio_slide()
        }
    });
    jQuery('BODY').on('click', '.navQuiz .nav-drop-close', function(){
        let thisObj = jQuery(this),
            block = thisObj.closest('.dropQuiz-menu')
            item = thisObj.closest('.nav-item')
            isOpen = item.hasClass('open');
        if(isOpen){
            block.removeClass('open');
            item.removeClass('open');
            // runAudio_slide();
        }
    });

    
    /* select Location */
    // jQuery('BODY').on('click', '.dropBlock .btnDrop', function(){
    //     let thisObj = jQuery(this),
    //         block = thisObj.closest('.dropBlock')
    //         isOpen = block.hasClass('open');
    //     if(!isOpen){
    //         thisObj.addClass('open');
    //         block.addClass('open');
    //         // runAudio_slide();
    //     } else {
    //         thisObj.removeClass('open');
    //         block.removeClass('open');
    //         // runAudio_slide();
    //     }
    // });
    /* end select Location */



    
    /* Function to animate height: auto */
    function autoHeightAnimate(element, time){
        var curHeight = element.height(), // Get Default Height
            autoHeight = element.css('height', 'auto').height(); // Get Auto Height
        element.height(curHeight); // Reset to Default Height
        element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
    }
    
    
    // run video
    function runAudio(audioUrl, volume){
        let audio = new Audio(audioUrl);
        audio.volume = volume;
        audio.play();
    }
    jQuery('.runVideo').on('click', function(){
        var thisObj = jQuery(this),
            videoRun = '#' + thisObj.data('video'),
            frameData = thisObj.data('frame'),
            video = jQuery(videoRun);
        thisObj.find('.btnPlay').animate({opacity: 0}, 3000);
        setTimeout( thisObj.find('.btnPlay').hide(), 3000);
        // thisObj.find('.btnPlay').hide();
        // thisObj.find('.usVideo').show();
        thisObj.find('.usVideo').show().animate({opacity: 1}, 1000);
        // console.log(videoRun);
        console.log('run video: '+videoRun);
        jQuery(frameData)
            // .load(function() {
            //     // frameData.prop('contentWindow').postMessage('{"event": "command", "func": "playVideo", "args": ""}', "*");
            // })
            .appendTo(videoRun);
    });


    jQuery('.modalVideoInsert').on('click', function(){
        var thisObj = jQuery(this),
            title = thisObj.data('title'),
            videoHref = thisObj.data('video'),
            videoIsVertical = thisObj.data('is-vertical'),
            modalTarget = thisObj.data('bs-target'),
            modal = jQuery(modalTarget),
            modalContent = jQuery(modalTarget + ' .modal-body'),
            // https://www.youtube.com/embed/pk1vtHhXWrA?origin=https%3A%2F%2Fmirum-clinic.ru&amp;autoplay=1
            iframe = '<iframe id="videoModalFrame" class="videoModalFrame" frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="' + title + '" width="100%" height="100%" src="https://www.youtube.com/embed/' + videoHref + '?origin=https%3A%2F%2Fmirum-clinic.ru&amp;autoplay=1&amp;rel=0"></iframe>';
        if(typeof modal != "undefined" && typeof modalContent != "undefined" && typeof videoHref != "undefined" && videoHref.length > 0){
            videoIsVertical == 1 || videoIsVertical == '1' || videoIsVertical == 'Y'
                ? modal.addClass('aspectVertical')
                : modal.removeClass('aspectVertical');
            modalContent.html(iframe);
        }
    });
    jQuery('.modalVideo').on('hide.bs.modal', function(){
        var thisObj = jQuery(this);
        thisObj.find('.modal-body').html('');
    });


    // youtube size
    window.youtubePlayer = [];
    window.tubeResize = function(){
        let playerContents = jQuery('.videoResizeBlock'),
            options = {
                ratio: 16/9,
                mute: true,
                repeat: true,
                width: $(window).width(),
                wrapperZIndex: 1,
                playButtonClass: 'tubular-play',
                pauseButtonClass: 'tubular-pause',
                muteButtonClass: 'tubular-mute',
                volumeUpClass: 'tubular-volume-up',
                volumeDownClass: 'tubular-volume-down',
                increaseVolumeBy: 10,
                start: 0
            };
        if(typeof playerContents != "undefined" && playerContents.length > 0){
            jQuery.each(playerContents, function(ind, val){
                let container = jQuery(val),
                    playerContent = container.find('.videoPlayer'),
                    width = container.width(),
                    pWidth, // player width, to be defined
                    height = container.height(),
                    pHeight; // player height, tbd
                console.log(playerContent.attr('id'));
                console.log('width content: '+width);
                console.log('height content: '+height);
                if(typeof playerContents != "undefined"){
                    // when screen aspect ratio differs from video, video must center and underlay one dimension ratio = coeficient
                    if (width / options.ratio < height) { // if new video height < window height (gap underneath)
                        pWidth = Math.ceil(height * options.ratio); // get new player width
                        playerContent.width(pWidth).height(height).css({left: (width - pWidth) / 2, top: 0}); // player width is greater, offset left; reset top
                    } else { // new video width < window width (gap to right)
                        pHeight = Math.ceil(width / options.ratio); // get new player height
                        playerContent.width(width).height(pHeight).css({left: 0, top: (height - pHeight) / 2}); // player height is greater, offset top; reset left
                    }
                }

                console.log('pWidth content: '+pWidth);
                console.log('pHeight content: '+pHeight);
                console.log('---------');
            });
        }
    }
    jQuery(document).ready(function(){ window.tubeResize(); });
    jQuery(window).on('resize.tubeResize', function(){ window.tubeResize(); });


    /* events audio */
    // function runAudio_slide(){ runAudio('/audio/pss.mp3', 0.1); }
    // jQuery('BODY').on('click', '.btn', function(){ runAudio('/audio/open.mp3', 0.1); });
    // jQuery('BODY').on('click', '.nav-link', function(){ runAudio('/audio/sverchki.mp3', 0.1); });
    // jQuery('BODY').on('click', '.btn-close', function(){ runAudio('/audio/close.mp3', 0.1); });
    // jQuery('.modal').on('hide.bs.modal', function(){ runAudio('/audio/close.mp3', 0.1); });
    // jQuery('.audio-waah').on('click', function(){ runAudio('/audio/waah.mp3', 0.1); });
    

    // open modal
    var oldLabelModal = '', oldDataLabelModal = '';
    jQuery('.getModal').on('click', function(){
        var thisObj = jQuery(this),
            target = thisObj.data('bs-target'),
            modalBlock = jQuery(target),
            modalLabel = typeof modalBlock != "undefined" ? modalBlock.find('.modal-body .nameLabel') : '',
            label = thisObj.data('label'),
            labelModal = thisObj.data('label-modal');
        // console.log(label);
        // console.log(modalBlock.find('FORM').data('label'));
        if(typeof labelModal != "undefined" && labelModal != ''){
            if(typeof modalLabel != "undefined" && modalLabel.text() != '' && modalLabel.length > 0){
                oldLabelModal = modalLabel.text();
                modalLabel.text(labelModal);
            }
        }
        if(typeof label != "undefined" && label != ''){
            oldDataLabelModal = modalBlock.find('FORM').data('label');
            modalBlock.find('FORM').data('label', label);
        }
    });
    jQuery('.modalWindow').on('hide.bs.modal', function(){
        var thisObj = jQuery(this),
            modalLabel = thisObj.find('.modal-header .nameLabel');
        if(typeof oldLabelModal != "undefined" && oldLabelModal != ''){
            modalLabel.text(oldLabelModal);
        }
        if(typeof oldDataLabelModal != "undefined" && oldDataLabelModal != ''){
            thisObj.find('FORM').data('label', oldDataLabelModal);
        }
        // getAlert('success', 'Ну хорошо', 'Закрыли: '+modalLabel.text(), 'top-end', false);
    });
    
    
    
    window.getAlert = function (type, title, text, position = '', showConfirm = true, template = ''){
        // Swal.fire({
        //     position: position ? position : 'default',
        //     title: title,
        //     text: text,
        //     type: type,
        //     showConfirmButton: showConfirm,
        //     confirmButtonClass: 'btn btn-success',
        //     confirmButtonText: 'Хорошо',
        //     template: template ? template : 'default', //'#my-template'
        //     icon: 'warning',
        //     // timer: 3500,
        //     // showClass: {
        //     //     popup: 'animate__animated animate__fadeInDown'
        //     // },
        //     // hideClass: {
        //     //     popup: 'animate__animated animate__fadeOutUp'
        //     // }
        // });
        jQuery('#alertModal .modal-body .nameLabel').html(title);
        jQuery('#alertModal .modal-body .hDesc').html(text);
        //jQuery('#alertModal').modal();
        let modal = new window.bootstrap.Modal(jQuery('#alertModal'));
        modal.show();
    }
    window.getAlertThanks = function (){
        let modal = new window.bootstrap.Modal(jQuery('#thanksModal'));
        modal.show();
    }

    
    // MODAL SEND
    jQuery('FORM[name=callMeGo]').submit(function(){
        var thisObj = jQuery(this),
            name = thisObj.find('INPUT[name=name]'),
            phone = thisObj.find('INPUT[name=phone]'),
            email = thisObj.find('INPUT[name=email]'),
            datetime = thisObj.find('INPUT[name=datetime]'),
            message = thisObj.find('TEXTAREA[name=message]'),
            url = thisObj.data('url'),
            iagree = thisObj.find('INPUT[name=politics]'),
            label = thisObj.data('label'),
            allOk = true,
            post = [];
        if(typeof name != "undefined"){
            if(name.val() == ''){
                getAlert('error', '<strong>Error</strong></br>Не все поля заполнены!', 'Введите свое имя!</br>Require name');
                name.addClass('error');
                name.focus();
                return false;
            } else {
                name = name.val();
            }
        } else {
            name = '';
        }
        if(typeof phone == "undefined" || typeof phone.val() == "undefined" || phone.val() == ''){
            phone = '';
        } else {
            if(phone.val() == ''){
                getAlert('error', '<strong>Error</strong></br>Не все поля заполнены!', 'Введите свой телефон!</br>Require Phone');
                phone.focus();
                return false;
            } else {
                var r2 = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i,
                    r8 = /^8 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                    // r8 = /^\+7 \(8[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                // console.log(phone.val());
                if(!r2.test(phone.val()) && !r8.test(phone.val())){
                    getAlert('error', '<strong>Error</strong></br>Не все поля заполнены!', 'Не верно введен телефон!</br>Error Phone type');
                    phone.addClass('error');
                    phone.focus();
                    return false;
                }
            }
            phone = phone.val();
        }
        if(typeof email == "undefined" || typeof email.val() == "undefined" || email.val() == ''){
            email = '';
        } else {
            email = email.val();
        }
        if(typeof message == "undefined" || typeof message.val() == "undefined" || message.val() == ''){
            message = '';
        } else {
            message = message.val();
        }
        if(typeof url == "undefined"){
            url = '';
        }
        if(typeof datetime == "undefined"){
            datetime = '';
        } else {
            datetime = datetime.val();
        }
        //console.log(iagree);
        if(typeof iagree == "undefined" || typeof iagree.val() == "undefined" || iagree.val() == ''){
            iagree = '';
        } else {
            if(iagree.prop('checked') != true){
                getAlert('error', '<strong>Error</strong></br>Не все поля заполнены!', 'Согласие с пользовательским соглашением и политикой конфиденциальности обязательно!</br>Consent to the user agreement and privacy policy is required!');
                allOk = false;
                return false;
            }
        }
        
        if(allOk){
            post = {name:name, phone:phone, email:email, datetime:datetime, label:label, url:url, other:message};
            console.log(JSON.stringify(post));
            callMeGo(JSON.stringify(post), thisObj);
            // ym(555555,'reachGoal','sendCallMe');
        }
        return false;
    });
    
    

    function callMeGo(post, thisObj){
        jQuery.ajax({
            url    : "/ajax/mail-to",
            type   : 'POST',
            //dataType : 'json',
            data : {data: post, sessid: jQuery('meta[name="_token"]').attr('content') },
            // headers: { 'X-CSRF-Token': jQuery('meta[name="_token"]').attr('content') },
            headers: { 'X-CSRF-Token': jQuery('meta[name="_token"]').attr('content') },
            cache  : false,
            //success: function(data){}
        })
        .done( function(data){
            console.log(data);
            //data = JSON.parse(data);
            if( data.status ){
                // Swal.fire({
                //     title: 'Спасибо за заявку!',
                //     text: 'Мы свяжемся с Вами в ближайшее время!',
                //     type: 'success',
                //     showConfirmButton: false,
                //     confirmButtonClass: 'btn btn-success',
                //     confirmButtonText: 'Хорошо',
                //     icon: 'success',
                // });
                getAlertThanks();
        
                // jQuery('#alertModal .modal-header .h3').text('Спасибо за заявку!');
                // jQuery('#alertModal .modal-header .hDesc').text("Мы свяжемся с Вами в ближайшее время!");
                // //jQuery('#alertModal').modal();
                // let modal = new bootstrap.Modal(jQuery('#alertModal'));
                // modal.show();
                jQuery('.modalWindow').modal('hide');
                // jQuery('INPUT[name=name]').val('');
                // jQuery('INPUT[name=phone]').val('');
                // jQuery('INPUT[name=email]').val('');
                // jQuery('INPUT[name=from]').val('');
                // jQuery('INPUT[name=to]').val('');
                // jQuery('SELECT').val('');
                // jQuery('TEXTAREA').val('');
                // jQuery('SELECT').val('0');
                // runAudio('/audio/victory.mp3', 0.01);
                // runAudio('/audio/est-probitie.mp3', 0.05);
            } else {
                getAlert('error', 'Ошибка на сервере', data.message);
                // jQuery('#alertModal .modal-header .h3').text('Ощибка на сервере');
                // jQuery('#alertModal .modal-header .hDesc').text("Повторите чуть позже ("+data.message+")");
                // //jQuery('#alertModal').modal();
                // let modal = new bootstrap.Modal(jQuery('#alertModal'));
                // modal.show();
            }
        })
        .fail(function(data){
            console.log(data);
            getAlert('error', 'Ошибка на сервере', data.message);
        });
    }


    // PRODUCT CARD
    // SKU
    /*
    window.reMatchColorsCardProduct = function(objCard, idSize){
        let objColors = objCard.find('.sku_colors .sku_color');
        objColors.addClass('disable');
        jQuery.each(objCard.find('.prices[class*="price_'+idSize+'_"]'), function(ind, val){
            let oVal = jQuery(val);
            // console.log('color act: ' + oVal.data('sku-color'));
            objCard.find('.sku_colors .sku_color_'+oVal.data('sku-color')).removeClass('disable');
        })
    }
    jQuery.each(jQuery('.cardProduct'), function(ind, val){
        window.reMatchColorsCardProduct(jQuery(val), jQuery(val).find('.sku_sizes .sku_size.active').data('sku-size'));
    });
    jQuery('.cardProduct .sku_colors').on('click', '.sku_color:not(.active)', function(e){
        let thisObj = jQuery(this),
            idColor = thisObj.data('sku-color'),
            card = thisObj.closest('.cardProduct'),
            priceOld = card.find('.prices.active');
        if(typeof idColor != "undefined"){
            let objPriceColor = card.find('.prices.price_'+idColor);
            let objSize = card.find('.sku_sizes .sku_size.active');
            if(typeof objSize != "undefined"){
                let idSize = objSize.data('sku-size');
                if(typeof idSize != "undefined"){
                    let objPriceColorSize = card.find('.prices.price_'+idSize+'_'+idColor);
                    // console.log('objPriceColorSize: ' + '.prices.price_'+idSize+'_'+idColor);
                    if(typeof objPriceColorSize != "undefined" && objPriceColorSize.length > 0){
                        card.find('.sku_colors .sku_color.active').removeClass('active');
                        thisObj.addClass('active');
                        priceOld.removeClass('active');
                        objPriceColorSize.addClass('active');
                    } 
                    // else {
                    //     // console.log('NO objPriceSize');
                    //     let strFinder = 'price_'+idSize+'_';
                    //     let arrPricesObj = card.find('[class*="'+strFinder+'"]');
                    //     // console.log(strFinder);
                    //     // console.log(arrPricesObj.length);
                    //     // console.log(arrPricesObj);
                    //     if(typeof arrPricesObj != "undefined" && arrPricesObj.length > 0){
                    //         let elPricesObj = arrPricesObj[0];
                    //         let objPricesObj = jQuery(elPricesObj);
                    //         let newIdSize = objPricesObj.data('sku-size');
                    //         // console.log(elPricesObj);
                    //         // console.log(objPricesObj);
                    //         console.log(newIdSize);
                    //         if(typeof newIdSize != "undefined"){
                    //             console.log('es RE of color');
                    //             card.find('.sku_colors .sku_color.active').removeClass('active');
                    //             thisObj.addClass('active');
                    //             card.find('.sku_sizes .sku_size.active').removeClass('active');
                    //             card.find('.sku_sizes .sku_size_'+newIdSize).addClass('active');
                    //             priceOld.removeClass('active');
                    //             objPricesObj.addClass('active');
                    //         }
                    //     } else {
                    //         // console.log('NO prices');
                    //     }
                    // }
                }
            } else if(typeof objPriceColor != "undefined" && objPriceColor.length > 0){
                card.find('.sku_colors .sku_color.active').removeClass('active');
                thisObj.addClass('active');
                priceOld.removeClass('active');
                objPriceColor.addClass('active');
            }
        }
    });
    jQuery('.cardProduct .sku_sizes').on('click', '.sku_size:not(.active)', function(e){
        let thisObj = jQuery(this),
            idSize = thisObj.data('sku-size'),
            card = thisObj.closest('.cardProduct'),
            priceOld = card.find('.prices.active');
        if(typeof idSize != "undefined"){
            let objPriceSize = card.find('.prices.price_'+idSize);
            let objColor = card.find('.sku_colors .sku_color.active');
            if(typeof objColor != "undefined"){
                // console.log('es objColor');
                let idColor = objColor.data('sku-color');
                if(typeof idColor != "undefined"){
                    // console.log('es idColor');
                    let objPriceColorSize = card.find('.prices.price_'+idSize+'_'+idColor);
                    // console.log('objPriceColorSize: ' + '.prices.price_'+idSize+'_'+idColor);
                    if(typeof objPriceColorSize != "undefined" && objPriceColorSize.length > 0){
                        // console.log('es objPriceSize');
                        card.find('.sku_sizes .sku_size.active').removeClass('active');
                        thisObj.addClass('active');
                        priceOld.removeClass('active');
                        objPriceColorSize.addClass('active');
                    } else {
                        // console.log('NO objPriceSize');
                        let strFinder = 'price_'+idSize+'_';
                        let arrPricesObj = card.find('[class*="'+strFinder+'"]');
                        // console.log(strFinder);
                        // console.log(arrPricesObj.length);
                        // console.log(arrPricesObj);
                        if(typeof arrPricesObj != "undefined" && arrPricesObj.length > 0){
                            let elPricesObj = arrPricesObj[0];
                            let objPricesObj = jQuery(elPricesObj);
                            let newIdColor = objPricesObj.data('sku-color');
                            // console.log(elPricesObj);
                            // console.log(objPricesObj);
                            // console.log(newIdColor);
                            if(typeof newIdColor != "undefined"){
                                console.log('es RE of size');
                                card.find('.sku_sizes .sku_size.active').removeClass('active');
                                thisObj.addClass('active');
                                card.find('.sku_colors .sku_color.active').removeClass('active');
                                card.find('.sku_colors .sku_color_'+newIdColor).addClass('active');
                                priceOld.removeClass('active');
                                objPricesObj.addClass('active');
                            }
                        } else {
                            // console.log('NO prices');
                        }
                    }
                }
            } else if(typeof objPriceSize != "undefined" && objPriceSize.length > 0){
                // console.log('es objPriceSize');
                card.find('.sku_sizes .sku_size.active').removeClass('active');
                thisObj.addClass('active');
                priceOld.removeClass('active');
                objPriceSize.addClass('active');
            }
            window.reMatchColorsCardProduct(card, idSize);
        }
    });
    */

});







